<template>
  <div id="add">
    <div class="left">
      <div class="title">
        <img
          src="../../img/板块图标 - 副本_slices/icon.png"
          style="width: 0.18rem"
          alt=""
        />
        <span>课程列表</span>
      </div>
      <!-- 无课程时显示 -->
      <div class="box" v-if="course.length == 0">
        <img
          src="../../img/上课/暂无数据 (1).png"
          alt=""
          style="width: 113px; height: 61px"
        />
        <div class="txt">当前暂无创建课程~</div>
      </div>
      <ul class="classList">
        <li
          @click="
            num = index;
            getId(item.id);
          "
          v-for="(item, index) in course"
          :key="index"
        >
          <!-- <div class="text"> -->
          <div class="line" :class="{ active: num == index }"></div>
          <span>{{ item.course_name }}</span
          ><el-dropdown
            trigger="click"
            placement="bottom-start"
            style="height: 50/96in; display: flex"
          >
            <div
              class="el-dropdown-link"
              style="display: flex; align-items: center; height: 30px"
            >
              <img
                class="more"
                src="../../img/板块图标 - 副本_slices/更  多 (1) 拷贝 6.png"
                style="width: 20/96in"
                alt=""
              />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                >编辑课程<img
                  src="../../img/板块图标 - 副本_slices/编辑.png"
                  alt=""
                  style="width: 0.1rem; margin-left: 0.1rem"
              /></el-dropdown-item>
              <el-dropdown-item
                >添加课程<img
                  src="../../img/板块图标 - 副本_slices/复制.png"
                  alt=""
                  style="width: 0.1rem; margin-left: 0.1rem"
              /></el-dropdown-item>
              <el-dropdown-item
                >删除课程<img
                  src="../../img/板块图标 - 副本_slices/删除 拷贝 5.png"
                  alt=""
                  style="width: 0.1rem; margin-left: 0.1rem"
              /></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- </div> -->
        </li>
      </ul>
    </div>
    <div class="middle">
      <div class="title">添加新课程</div>

      <div class="classesinfo">
        <!-- 课程基本信息 -->
        <div class="course">
          <div class="title">*课程基本信息</div>
          <label for="classname">课程名称<span>*</span></label>
          <el-input
            v-model="classname"
            id="classname"
            placeholder="请输入课程名称"
          ></el-input
          ><br />
          <label for="timer">课程总课时<span>*</span></label>
          <el-input
            v-model="timer"
            id="timer"
            :placeholder="infoArr.length"
            readonly
          ></el-input>
        </div>
        <!-- 创建课程课时 -->
        <ul class="classtime">
          <div class="title ct">*创建课程课时</div>
          <li v-for="(item, index) in infoArr" :key="index">
            <label for="classTimename">课时名称<span>*</span></label>
            <el-input
              id="classTimeName"
              v-model="name[index]"
              placeholder="请输入课时名称"
              clearable
            ></el-input
            ><br />
            <label for="imgs" id="imgs" @click="getIndex(index)">
              <p>
                课时封面图
                <!-- <span style="color: #000">*</span> -->
                <span>&nbsp;</span>
              </p>
              <el-upload
                class="avatar-uploader"
                accept=".jpg, .jpeg, .png"
                action="false"
                :show-file-list="false"
                :http-request="upload"
              >
                <div v-if="imageUrl[index]">
                  <img :src="imageUrl[index]" class="avatar" />
                </div>

                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </label>
            <div class="sinfo">建议上传尺寸：600*450的jpg或png格式图片</div>
          </li>
        </ul>
        <!-- 保存 -->
        <div class="btnbox">
          <div class="addtime" @click="addtime">
            <img
              src="../../img/板块图标 - 副本_slices/添加.png"
              style="width: 16/96in"
              alt=""
            />
            <b>添加课时</b>
          </div>
          <div class="storeBtn" @click="add()">保存课程</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
// const a = require("../../../WebConfig");
import * as util from "@/unit/network";
// import axios from "axios";
export default {
  data() {
    return {
      num: 0,
      classname: "",
      timer: "",
      // classTimeName: "",
      imageUrl: [],
      name: [],
      cover: [],
      token: "",
      infoArr: [],
      pushA: true,
      course: "",
      section: "",
      info: "",
      index: "",
      // imgss: a.ROOT + "/upload_img",
    };
  },
  mounted() {
    this.token = this.getCookie("token");
    this.getinfo();
    this.addtime();
  },
  methods: {
    upload(e) {
      let formData = new FormData();
      // formData重复的往一个值添加数据并不会被覆盖掉，可以全部接收到，可以通过formData.getAll('files')来查看所有插入的数据
      formData.append("file", e.file);
      util.post_file("/upload_img", formData, (res) => {
        // if (this.imageUrl[this.index] == "") {
        //   this.imageUrl.push(res.data);
        //   this.cover.push(res.data);
        // } else {
        this.imageUrl[this.index] = res.data;
        this.cover[this.index] = res.data;
        // }
      });
    },
    getIndex(i) {
      this.index = i;
    },

    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    },
    addClasses() {
      this.$router.push("/main/addclasses");
    },
    pushArr() {
      this.name.map((item, index) => {
        console.log(item, index);
        this.infoArr.push({
          name: this.name[index],
          cover: this.cover[index],
        });
        // this.infoArr.splice(0, 1);
      });
    },
    add() {
      if (this.pushA) {
        this.pushArr();
      }
      this.pushA = false;

      let newArr = this.infoArr.filter((item) => {
        return item.name != "";
      });
      let obj = {};
      let peon = newArr.reduce((cur, next) => {
        obj[next.name] ? "" : (obj[next.name] = true && cur.push(next));
        return cur;
      }, []);

      this.infoArr = peon;
      this.timer = peon.length;
      // console.log(index);
      util.post(
        "/addCourse",
        {
          course_name: this.classname,
          course_time: this.timer,
          course_section: this.infoArr,
        },
        (res) => {
          console.log(res);

          this.$router.push("/main/begins");
        },
        (err) => {
          console.log(err);
          this.addtime();
        }
      );
    },
    addtime() {
      this.infoArr.push({
        name: "",
        cover: "",
      });
      this.pushA = true;
    },
    getinfo() {
      util.post("/course/index", {}, (res) => {
        console.log(res);
        if (res.code == 1) {
          this.course = res.data;
          this.getId(res.data[0].id);
        }
      });
    },
    getId(id) {
      util.post("/section/index", { cid: id }, (res) => {
        this.section = res.data.section;
        this.info = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
#add {
  // height: calc(100vh - 0.6rem /* 60/100 */);
  display: flex;
  height: 100%;

  .left {
    width: 2.38rem /* 238/100 */;
    height: 100%;
    border: 1px solid #cdcdcd;
    border-top: none;
    border-bottom: none;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 82px;
      img {
        width: 36px;
        height: 45px;
      }
      .txt {
        font-size: 14px;
        font-weight: 400;
        color: #8a8e99;
        margin-top: 19px;
      }
    }
    .title {
      height: 67px;
      border-bottom: 1px solid #cdcdcd;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0.14rem /* 14/100 */;
      span {
        font-size: 0.2rem /* 20/100 */;
        font-weight: bold;
        margin-left: 0.08rem /* 8/100 */;
        color: #131313;
      }
    }
    .classList {
      font-size: 0.14rem /* 14/100 */;
      font-weight: 500;
      color: #000000;
      line-height: 0.63rem /* 63/100 */;
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 0.17rem /* 17/100 */;
        cursor: pointer;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
        .line {
          width: 0.05rem /* 5/100 */;
          height: 0.23rem /* 23/100 */;
          margin-right: 0.32rem /* 32/100 */;
        }
      }
    }
    .add {
      display: flex;
      justify-content: center;
      margin-top: 1.2rem /* 120/100 */;
      .addbtn {
        width: 1.4rem /* 140/100 */;
        height: 0.45rem /* 45/100 */;
        background-color: #1c5eff;
        border-radius: 0.05rem /* 5/100 */;
        color: #fcfcfc;
        font-size: 0.15rem /* 15/100 */;
        line-height: 0.45rem /* 45/100 */;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .middle {
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: auto;
    > .title {
      height: 67/96in;
      border-bottom: 1px solid #cdcdcd;
      font-size: 20/96in;
      font-weight: bold;
      color: #000000;
      line-height: 72/96in;
      padding-left: 27/96in;
    }
    // 创建课程
    .classesinfo {
      padding: 35/96in 46/96in;
      padding-bottom: 0;
      box-sizing: border-box;
      span {
        color: red;
      }
      .title {
        font-size: 18/96in;
        font-weight: 500;
        color: #000000;
        margin-bottom: 37/96in;
      }
      .ct {
        margin-top: 27/96in;
      }
      .classtime {
        margin-bottom: 52/96in;
      }
      label {
        display: inline-block;
        width: 89/96in;
        text-align: right;
        margin-right: 33/96in;
        font-size: 16/96in;
        margin-left: 17/96in;
        margin-bottom: 12/96in;
      }
      /deep/.el-input {
        width: 350/96in;
        height: 40/96in;
        margin-bottom: 24/96in;
        border-color: #949595;
        color: #010101;
      }
      li {
        margin-bottom: 52/96in;

        // file
        #imgs {
          display: flex;
          width: 100%;
          p {
            margin-right: 36/96in;
          }
        }

        div.sinfo {
          font-size: 12/96in;
          font-weight: 400;
          color: #949595;
          margin-left: 140/96in;
          margin-top: 17/96in;
        }
      }
      .btnbox {
        width: 765px;
        position: relative;
      }
      // 添加课时
      .addtime {
        position: absolute;
        left: 5.40625in;
        bottom: 263/96in;
        width: 110/96in;
        height: 36/96in;
        background: #1c5eff;
        border-radius: 5/96in;
        font-weight: 500;
        color: #ffffff;
        font-size: 14/96in;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        b {
          font-weight: 400;
          margin-left: 9/96in;
        }
      }
    }
    .storeBtn {
      // position: relative;
      width: 100/96in;
      height: 36/96in;
      background: #1c5eff;
      border-radius: 5/96in;
      font-size: 14/96in;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 36/96in;
      margin-left: 664/96in;
      margin-bottom: 70/96in;
      cursor: pointer;
    }
  }
}
// @media screen and (max-width: 1280/96in) {
//   .functions {
//     width: 51.19/96in !important;
//   }
//   .mains {
//     .left {
//       width: 158.66/96in !important;
//       .title {
//         width: 156.66/96in;
//       }
//       .classList {
//         .more {
//           width: 13.33/96in !important;
//         }
//         .li {
//           .text {
//             width: 108.66/96in !important;
//           }
//         }
//       }
//     }
//     .right {
//       width: 186/96in !important;
//     }
//   }
//   .middle {
//     width: 1020/96in !important;
//   }
// }
.el-dropdown-menu {
  box-shadow: 0px 4px 23px 0px rgba(28, 94, 255, 0.1);
  font-size: 0.14rem /* 14/100 */;
  color: #575759;
}
.active {
  background-color: #1c5eff;
}
/deep/.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid #949595;
  border-radius: 5px;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28/96in;
  color: #8c939d;
  width: 190/96in;
  height: 110/96in;
  line-height: 110/96in;
  // border: 1px solid #949595;
  // border-radius: 5px;
  text-align: center;
}
/deep/.avatar {
  width: 190/96in;
  height: 110/96in;
  display: block;
}
</style>